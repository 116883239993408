import { useParams } from 'react-router-dom';
import './mobileApps.css';

function PrivacyPolicyPage () {

    const { appName } = useParams();
    const validAppNames = [
        'BlockerX',
        'Zenze',
        'Quit Porn',
        'Website Blocker',
        'App Blocker',
        'Quit Porn Addiction',
        'Parental Control'
    ]

    if (validAppNames.includes(appName)) {
        return (
            <section className='privacy_policy_page'>
                <h1>{ appName } – Privacy Policy</h1>
                <h2>Initial effective date: 08 Jan 2025</h2>
                <h2>Last modified date: 08 Jan 2025</h2>
                <p>{ appName } is committed to protecting the privacy of both the information you share with us and the information that we gather through the course of us providing our services to you. If you have any questions about this privacy policy, please feel free to contact us at <a href='mailto:support@blockerx.org'>support@blockerx.org</a>. </p>
                <p>Welcome to { appName } mobile app of Atmana Tech – FZCO (hereinafter referred to as “{ appName }”, or “We”, or “Us” or “Our”). </p>
                <p>{ appName } (“SOFTWARE PRODUCT”) is owned and managed by Atmana Tech – FZCO, a Freezone company formed and registered under the Implementing Regulations No.1 of 2006 issued pursuant to Law No. (7) of 2004 as amended by Law No. 16 of 2003, with its principal office at IFZA Properties, Dubai Silicon Oasis, Dubai, United Arab Emirates.</p>
                <p>This Agreement applies to all visitors, users, and others who access the Service (“User(s)” or “You/r”).This page explains the terms by which You may use Our Website, SOFTWARE PRODUCT, or (or “Platform“) or any online and/or mobile services, through the mobile software and software provided on or in connection with the services (collectively the “Service”). By accessing or using the Service, You signify that You have read, understood, and agree to be bound by these Terms of Usage Agreement (“{ appName } Terms Of Usage” or “Agreement”) and to the collection and use of Your information as set forth in the Privacy Policy, and terms of any other policies of { appName } app as may be applicable to You whether or not You are a registered user of Our Service. This Agreement, Privacy Policy and any other terms and conditions as may be applicable shall hereinafter collectively be referred to as “Terms and Conditions”. If You disagree with any parts contained in this Terms and Conditions please do not use the Service.</p>
                <h4>Information we collect and how we use it </h4>
                <p><strong>Information you provide upon signup</strong>- When you sign up for or modify a { appName } account, you provide us with personal information about yourself and the people that you list on your account. This information includes names, email IDs, and passwords created for { appName }, settings for our services, and billing information. We use this information to maintain your account and deliver services to people listed on the account. </p>
                <p><strong>Information passively gathered while using our Service</strong>- When you use the { appName } app, some information on your account will be stored in our servers. We use this information to deliver the functionality of the app and to learn how people use our app so that we can improve them. Information passively provided by other vendors—We may use other vendors, including Google Play services, Firebase, Amplitude etc. which collect information about your use of our apps and allow us to carry out analytics in order to improve the product. </p>
                <p><strong>One-on-one communications</strong>- We may keep records of our communications with you that involve customer support and sales. We use this information to follow up on issues with customers, determine the frequency and severity of technical issues, improve our sales, and for training employees. Unless you specifically request to be added to a mailing list, you will not be added to any marketing communications as a result of these personal requests. </p>
                <p><strong>Password protection</strong>- One of our core services is password protection, which requires that an ally (also known as ‘accountability partner’) be shared an access code. In order to provide this feature, we collect the email ID of your accountability partner. </p>
                <h4>When do we share information? </h4>
                <p>We share information with others only when we must in order to deliver our services to you or when legally compelled to do so. These are the scenarios in which we share private information.</p>
                <p>{ appName } users specify other people who will serve as their allies (also known as ‘accountability partners’). Each ally is thus granted permission to receive reports of a user’s device activity. We provide these reports via email.</p>
                <p>We may share information with strategic partners that work with { appName } only to improve your experience with our products, services, and advertising. This information may include limited information only for the sake of research and analytics. </p>
                <p>We may share information about metadata concerning multiple users’ aggregated usage statistics; for instance, we may say something like “The average no. of blocked windows per month across all our users is ‘x’ [that is, some specific number].” </p>
                <p>We will disclose information when required by the order of a court with proper authority. </p>
                <h4>Children’s Privacy </h4>
                <p>We do not communicate with nor sell products or services to children. We do not knowingly collect personal information from children or sell any products to children. We take children’s privacy seriously and encourage the legal guardians of children to play an active role in their children’s online experience at all times. { appName } provides you, as a legal guardian of a child or children under the age of 18, with the ability to use our services to protect from adult/unwanted content. Our Service allows parents to enable the services of { appName } on mobile devices belonging to their children. The Service is intended to be used by children under 18 only with significant parental involvement and approval. If you believe that we might have inadvertently collected information from a child under 18 without parental consent, please contact us at <a href='mailto:support@blockerx.org'>support@blockerx.org</a></p>
                <h4>Links to other websites and services </h4>
                <p>We are not responsible for the practices employed by websites or services linked to or from the Service, nor the information or content contained therein. Please remember that when you use a link to go from the Service to another website, our Privacy Policy does not apply to third-party websites or services. Your browsing and interaction on any third-party website or service, including those that have a link on our website, are subject to that third party’s own rules and policies. Please read over those rules and policies before proceeding. </p>
                <h4>Changes to this policy </h4>
                <p>{ appName } may, in its sole discretion, modify or update this Privacy Policy from time to time, and so you should review this page periodically. When we change the policy, we will update the ‘last modified’ date at the top of this page. </p>
                <h4>Contact </h4>
                <p>If you have any questions about this Privacy Policy, contact us at <a href='mailto:support@blockerx.org'>support@blockerx.org</a> </p>
            </section>
        )
    } else {
        return (
            <section>
                <h1>Page not found</h1>
            </section>
        )
    }
}

export default PrivacyPolicyPage;