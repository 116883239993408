import { useParams } from 'react-router-dom';
import './mobileApps.css';

function TermsOfUsagePage () {

    const { appName } = useParams();
    const validAppNames = [
        'BlockerX',
        'Zenze',
        'Quit Porn',
        'Website Blocker',
        'App Blocker',
        'Quit Porn Addiction',
        'Parental Control'
    ]

    const privacyPolicyLink = `https://accounts.blockerx.net/atmana/privacy-policy/${appName}`

    if (validAppNames.includes(appName)) {
        return (
            <section className='privacy_policy_page'>
                <h1>{ appName } – Terms of Usage</h1>
                <h2>Initial effective date: 08 Jan 2025</h2>
                <h2>Last modified date: 08 Jan 2025</h2>
                <p>Welcome to { appName } mobile app of Atmana Tech – FZCO. (hereinafter referred to as "{ appName }", or “We”, or “Us” or “Our”).</p>
                <p>The { appName } mobile app (“SOFTWARE PRODUCT”) is owned and managed by Atmana Tech – FZCO, a Freezone company formed and registered under the Implementing Regulations No.1 of 2006 issued pursuant to Law No. (7) of 2004 as amended by Law No. 16 of 2003, with its principal office at IFZA Properties, Dubai Silicon Oasis, Dubai, United Arab Emirates. . </p>
                <p>This Agreement applies to all visitors, users, users’ allies* and others who access the Service (“User(s)” or “You/r”).This page explains the terms by which You may use Our Website, SOFTWARE PRODUCT, or (or “Platform“) or any online and/or mobile services, through the mobile software and software provided on or in connection with the services (collectively the “Service”). By accessing or using the Service, You signify that You have read, understood, and agree to be bound by these terms of use Agreement (“{ appName } Terms Of Usage” or “Agreement”) and to the collection and use of Your information as set forth in the Privacy Policy, and terms of any other policies of { appName } app as may be applicable to You whether or not You are a registered user of Our Service. This Agreement, Privacy Policy and any other terms and conditions as may be applicable shall hereinafter collectively be referred to as “Terms and Conditions”. If You disagree with any parts contained in this Terms and Conditions please do not use the Service. </p>
                <p>*{ appName } users specify other people who will serve as their allies (also known as ‘accountability partners’) </p>
                <h2>PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. </h2>
                <h4>General Terms: </h4>
                <p>SOFTWARE PRODUCT here means Software, image files, all accompanying files, data and materials received with your installation of “{ appName } app”. </p>
                <p>This SOFTWARE PRODUCT is for personal use only and may be installed and used by only one handset. Its component parts may not be separated for use on more than one handset. All components accompanying the software are copyrighted by Atmana Tech – FZCO. (hereinafter referred to as “THE COMPANY”) and may not be taken apart, modified, used or published with other software or means except with the SOFTWARE PRODUCT software and may not be distributed or copied in any manner. </p>
                <p>This SOFTWARE PRODUCT, all accompanying files, data and materials are distributed “AS IS” and with no warranties of any kind, whether expressed or implied. The user must assume all risk of using the program. This disclaimer of warranty constitutes an essential part of the agreement. </p>
                <p>Any liability of THE COMPANY will be limited exclusively to refund of purchase price within 48 hours of purchase. In addition, in no event shall THE COMPANY, or its principals, shareholders, officers, employees, affiliates, contractors, subsidiaries, or parent organizations, be liable for any incidental, consequential, punitive or any other damages whatsoever relating to the user of SOFTWARE PRODUCT. </p>
                <p>In addition, in no event does THE COMPANY authorize you to use this SOFTWARE PRODUCT in applications or systems where SOFTWARE PRODUCT’s failure to perform can reasonably be expected to result in a physical injury, or in loss of life. Any such use by you is entirely at your own risk, and you agree to hold THE COMPANY harmless from any claims or losses relating to such unauthorized use. </p>
                <p>This agreement constitutes the entire statement of the Agreement between the parties on the subject matter, and merges and supersedes all other or prior understandings, purchase orders, agreements and arrangements. This agreement shall be governed by US laws. </p>
                <p>Atmana Tech – FZCO is the owner of the copyright of this SOFTWARE PRODUCT, all of its derivatives, title and accompanying materials. All rights of any kind, which are not expressly granted in this License, are entirely and exclusively reserved to and by THE COMPANY. All rights of any kind, which are not expressly granted in this License, are entirely and exclusively reserved to and by THE COMPANY. You may not rent, lease, transfer, modify, translate, reverse engineer, decompile, disassemble or create derivative works based on this SOFTWARE PRODUCT. You may not make access to SOFTWARE PRODUCT available to others in connection with a service bureau, application service provider, or similar business, or use this SOFTWARE PRODUCT in a business to provide file compression, decompression, or conversion services to others. There are no third party beneficiaries of any promises, obligations or representations made by THE COMPANY herein. </p>
                <p>To use this SOFTWARE PRODUCT: </p>
                <ul>
                    <li>You warrant that you are 18 years of age or older. </li>
                    <li>You warrant that you will not use the application for any person for whom you are not the legal guardian. </li>
                    <li>You will not use the Site or Service in violation of any Federal, State or local law, rule, ordinance or governmental regulation. </li>
                    <li>You will only use the Site or Service to block content on the mobile device of children for whom you are the legal guardian. </li>
                    <li>You will properly follow all instructions and documentation provided by { appName } to you. </li>
                    <li>You will not allow any other person to access the information that the Site and Service collect from the mobile devices on which the Software is installed.</li>
                    <li>You will only install the application in mobile devices for which you are the authorised owner. </li>
                </ul>
                <p>By using this SOFTWARE PRODUCT, you also agree to our Privacy Policy which can be found here: <a href={privacyPolicyLink}>Privacy Policy </a> </p>
                <h4>Other terms: </h4>
                <ol>
                    <li><strong>Restriction against reselling license to use the SOFTWARE PRODUCT: </strong>You, as the User, agree not to purchase and then resell the SOFTWARE PRODUCT, nor include the SOFTWARE PRODUCT as any package which you otherwise sell or market, without express written consent of { appName }. </li>
                    <li><strong>Voluntary provision of information and agreement to disclose; protection of password: </strong>You, as the User, are voluntarily providing { appName } with certain information and with permission to monitor and block content on your computing device or on the computing device of a child for whom you are the legal guardian. You, as the User, agree that all users of your computing device / { appName } account will be informed that monitoring/blocking is happening, and that in so being informed, other users will not have their privacy invaded. You should recognize the value of your { appName } account, and that casually allowing others to use your account can lead to inappropriately ascribing computing device use to your own account; and you, the User, agree that { appName } is not responsible for maintaining the privacy of the account information either of you or your allies. </li>
                    <li><strong>Agreement to comply with laws and restrictions on harmful transmissions: </strong>You, as the User, agree that you will comply with all applicable laws regarding computing device use, both now and in the future as laws are generated which may affect the use of the computing device. You also agree not to attempt to pursue in any way any transmission of information to { appName } servers or other equipment which could be harmful to its database or other functions. The transmission of any material in violation of United States regulations is expressly prohibited, and will result in immediate termination of use. </li>
                    <li><strong>No warranty or guarantee of service: </strong>{ appName } makes no warranties, express or implied, including, but not limited to, those of merchantability or fitness for a particular purpose. This includes loss of data resulting from delays, nondeliveries, misdeliveries, or service interruption however caused. { appName } does not guarantee continuous or uninterrupted monitoring of computing device use or delivery of service. Routine maintenance and periodic system repairs, upgrades and reconfigurations, public emergency or necessity, force majeure, restrictions imposed by law, acts of God, labor disputes and other situations, including mechanical or electronic breakdowns may result in temporary impairment or interruption of service. Every effort will be made to perform routine maintenance between 3 a.m. and 8 a.m. (PST). </li>
                    <li><strong>Limitations on security of email transmissions: </strong>The security of any/all email messages sent by { appName } cannot be guaranteed on the Internet as information can be intercepted, corrupted, lost, destroyed, arrive late or incomplete, or contain viruses. { appName } does not accept liability for any errors, omissions or viruses in the contents of email correspondence sent to you or your Ally which arise as a result of email transmission. </li>
                    <li><strong>Data provided not to be construed as an interpretation of the data: </strong>Reports and other data provided by { appName } to you, the User, or to your Allies consist of data without interpretation. Like all monitoring and filtering programs, there are means of circumvention, so nothing in this agreement or in any other publications or correspondence by { appName }, Inc., shall be construed to imply that monitoring and/or filtering is performed 100% of the time, or is accurate 100% of the time. The analysis of browsing activity provided by { appName }’ proprietary filtering procedures and algorithms is, in itself, data corresponding to the results of those procedures and algorithms. It is entirely up to you as the User and to your Allies to interpret the data (including the Report Evaluation) in terms of the actual content of any particular image or images, the appropriateness for the User viewing those images, or any motivation or pattern of viewing by the User. </li>
                </ol>
                <p>Our Reports are intended for use in personal recovery from pornography struggles. As such, { appName } does not endorse or support the use of Reports in a premeditated fashion for legal purposes, e.g. to build a lawsuit or as specific evidence of misuse of the Internet by parolees. </p>
                <ol>
                    <li><strong>Disclosure as to accuracy of rating: </strong>Owing to the ever-changing nature of computing devices and the internet, no rating system and/or filter application will perform without error 100% of the time. { appName } does not accept liability and makes no warranties, express or implied, including, but not limited to, incorrect ratings, which may also result in incorrect blocking and/or allowing websites when the filter is in use, whether the rating is claimed to be too high or too low, of any/all website source code, websites, website domains, website URLs, website titles, screen images regardless of source, and electronic transmissions through any/all internet protocols. { appName } strives for perfection in its Dynamic Scoring Filter and Accountability Software applications. Occasionally, but rarely, a website may be flagged as adult website incorrectly. If you believe a website is flagged incorrectly, you may request a rating change. { appName } reserves the right to accept or deny any/all rating change requests. Final judgment on the rating of any particular website will remain at the sole discretion of { appName }. </li>
                    <li><strong>Rating Change Request constitutes request to view Report: </strong>When a rating change request or request by phone, email, or other means for assistance in understanding a report is submitted by the User and/or other members on this account and/or an Ally, said request constitutes permission by you, the User, to allow { appName } to view said report for the purpose of analysis. The said request itself shall be retained by { appName } indefinitely, but the associated Report will not be retained; however, any portions of Reports included in email correspondence between the member or Ally and { appName } will be retained by { appName } for the purposes of documenting the correspondence. { appName } will not make interpretations of data provided in Reports. </li>
                    <li><strong>Indemnification, limit of liability: </strong>You shall indemnify { appName }, its shareholders, affiliates, officers, directors, licensees and licensors from any and all claims and expenses, including, without limitation, attorney’s fees arising from any cause. Furthermore, liability of { appName } shall in any case be limited to and shall not exceed the total amount of monies paid by you, the User, to { appName } for use of the { appName } Software. The parties agree that any dispute arising from this agreement shall have as its venue Delaware, US, wherein are located the offices of { appName }. </li>
                </ol>
                <h4>In-app purchases: </h4>
                <ol>
                    <li>SUBSCRIPTION 
                        <ol>
                            <li>{ appName } users may access the product in two ways: 
                                <ol>
                                    <li>Free Usage: Access to limited features of the app like adult blocker is free. { appName } reserves the right to change the features available under free usage without prior intimation to the users. </li>
                                    <li>Premium Subscription: a subscription fee-based program, which gives access to all content including and beyond those available under free usage. You will only have access to the Subscription Program while your subscription is active and subsisting. All subscription services provide access through the Products. You can become a subscriber to the Subscription Program by purchasing a subscription to the Products from the app by the App marketplace partners like Google Play. If you purchase a subscription through the Google Play store, the sale is final and we will not provide a refund. Your purchase will be subject to Google’s applicable payment policy, which also may not provide for refunds. </li>
                                </ol>
                            </li>
                            <li>{ appName } offers monthly, six monthly and yearly subscription options. For the purposes of our monthly and yearly subscriptions, a month constitutes 30 calendar days, six months constitutes 180 calendar days, and a year constitutes 365 calendar days. </li>
                            <li>Our “Monthly” subscription is paid in monthly installments. For each month that your monthly subscription is active, you acknowledge and agree that { appName } is authorized to charge the same credit card as was used for the initial subscription fee or other payment method as set forth in section (f) (the “Payment Method”) in the amount of the current monthly subscription fee as of the time of renewal. The monthly renewal subscription fees will continue to be billed to the Payment Method you provided, automatically until cancelled. You must cancel your subscription before it renews each month in order to avoid billing of the next month’s subscription fee to the Payment Method you provided. Refunds cannot be claimed for any partial-month subscription period. </li>
                            <li>Subscription: { appName } app offers certain special discount pricing options (the “Special Discount Pricing Options”). The Special Discount Pricing Options will permit users to access to the same content included in the Paid Subscription; such Special Discount Pricing Options shall only be available at certain dates. { appName } app reserves the right to provide discounted prices during certain periods of time. { appName } app may also cancel such discounted pricing options at it’s sole discretion without any prior notice. </li>
                            <li>You agree to promptly notify { appName } app of any changes to the Payment Method you provided while any subscriptions remain outstanding. You are responsible for all applicable fees and charges incurred, including applicable taxes, and all subscriptions purchased by you. </li>
                            <li>In the course of your use of the Products, { appName } app and its third party payment service provider may receive and implement updated credit card information from your credit card issuer in order to prevent your subscription from being interrupted by an outdated or invalid card. This disbursement of the updated credit card information is provided to { appName } app and { appName } app’s third party payment service provider at the sole election of your credit card issuer. Your credit card issuer may give you the right to opt-out of the update service. Should you desire to do so, please contact your credit card issuer. </li>
                            <li>You will receive email from our marketplace partners (e.g. Google Play) that confirms your Order, along with other details of the order. Please quote the Order number in all subsequent correspondence with us. If payment is not received by us from the Payment Method you provided, you agree to pay all amounts due upon demand by us. You agree that you are not permitted to resell any Products purchased through { appName } app for commercial purposes.</li>
                        </ol>
                    </li>
                    <li>CHANGING FEES AND CHARGES: 
                        <p>We reserve the right to change our subscription plans or adjust pricing for our service or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion. Except as otherwise expressly provided for in these Terms, any price changes or changes to your subscription plan will take effect following notice to you. </p>
                    </li>
                    <li>CANCELLATION OF SERVICES:
                        <ol>
                            <li>CANCELLATION BY YOU:
                                <ol>
                                    <li>You may cancel a Monthly subscription at any time. </li>
                                    <li>If you purchase a subscription through the Google Play store you may cancel automatic renewals in account settings under Subscriptions in the Google Play app, or according to the current process outlined by Google Play. The latest process for cancellation can be found here: <a href='https://support.google.com/googleplay/answer/7018481'>Google Play</a></li>
                                </ol>
                            </li>
                            <li>CANCELLATION BY US: 
                                <p>We may suspend or terminate your use of the Products as a result of your fraud or breach of any obligation under these Terms. Such termination or suspension may be immediate and without notice. A breach of these Terms, includes without limitation, the unauthorized copying or download of our audio or video content from the Products. </p>
                            </li>
                            <li>PROMOTION AND DISCOUNT CODES: 
                                <p>Any promotion code or offer (including the Special Discount Pricing Options) provided by us may not be used in conjunction with any other promotion code or offer, past or present. Introductory offers are only available to new users of the Products, except where expressly stated otherwise. Previous users or trial users of the Products do not qualify as new users. No promotion code or discount will apply to corporate or other Community subscriptions. Unless otherwise set forth in the terms of any promotion, all pricing promotions or discounts will apply to the initial period of the subscription, and any renewals will be charged at the rate in effect at the time of renewal for the type of subscription purchased. </p>
                            </li>
                        </ol>
                    </li>
                </ol>
                <h4>Privacy</h4>
                <p>We deeply care about the privacy of Our Users. You understand that by using the Services You agree to the collection, use and disclosure of Your information including personal and non-personal information as set forth in our Privacy Policy, and to have Your information collected, used, transferred to and processed by Us in accordance with Our <a href={privacyPolicyLink}>Privacy Policy</a>. </p>
                <p>This SOFTWARE PRODUCT and all services provided may be used for lawful purposes only. Transmission, storage, or presentation of any information, data or material in violation of any US laws is strictly prohibited. This includes, but is not limited to: copyrighted material, material we judge to be threatening or obscene, or material protected by trade secret and other statute. You agree to indemnify and hold THE COMPANY harmless from any claims resulting from the use of this SOFTWARE PRODUCT, which may damage any other party. </p>
                <h4>Amendments to this agreement: </h4>
                <p>This Agreement may be amended from time to time by { appName } without notification to users; such amendments will be made either by changes in this Agreement on the { appName } website, or by email. </p>
                <h4>Contact </h4>
                <p>If you have any questions or suggestions about our Terms of usage, do not hesitate to contact us at <a href='mailto:support@blockerx.org'>support@blockerx.org</a>. </p>
            </section>
        )
    } else {
        return (
            <section>
                <h1>Page not found</h1>
            </section>
        )
    }
}

export default TermsOfUsagePage;